export const communityDetailsApi = (address,refresh) =>{
    let param = '';
    if(refresh){
        param = '?refresh=1';
    }
    const url = 'https://v2.nihaobnb.com/app/api/index-details/'+address+param;
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const dataDetailsApi = (address,fullUrl,type,page) =>{
    let url = '';
    if(fullUrl){
        url = fullUrl;
    }else{
        url = 'https://v2.nihaobnb.com/app/api/details-data/'+address;
        if(type){
            url = url+'?'+type+'='+page;
        }
    }
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const withdrawApi = (address,amount,captchaValue) =>{
    const url = 'https://v2.nihaobnb.com/app/api/withdraw';
    let params = {
        address:address,
        amount:amount
    }

    var formData = new FormData();
    formData.append('address', address);
    formData.append('amount', amount);
    formData.append('g-recaptcha-response', captchaValue);

    const options = {
        method: 'POST',
        headers: {Accept: 'application/json'},
        body:formData
    };
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                console.log('json',json);
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('amount')){
                        result.error = json.errors['amount'];
                    }
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('address')){
                        result.error = json.errors['address'];
                    }
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
    })
}

export const missingTransactionApi = (t_hash) =>{
    const url = 'https://v2.nihaobnb.com/app/api/missing-transaction';
    let params = {
        t_hash:t_hash
    }

    var formData = new FormData();
    formData.append('t_hash', t_hash);
    const options = {
        method: 'POST',
        headers: {Accept: 'application/json'},
        body:formData
        //body:JSON.stringify(params)
    };
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                console.log('json',json);
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    result.message = json.message;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('t_hash')){
                        result.error = json.errors['t_hash'];
                    }
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    if(json.hasOwnProperty('message')){
                        result.error = json.message;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
    })
}

export const networkSettingApi = (address,default_leg) =>{
    const url = 'https://v2.nihaobnb.com/app/api/network-settings';
    let params = {
        address:address
    }

    var formData = new FormData();
    formData.append('address', address);
    formData.append('default_leg', default_leg);
    const options = {
        method: 'POST',
        headers: {Accept: 'application/json'},
        body:formData
        //body:JSON.stringify(params)
    };
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                console.log('json',json);
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    result.message = json.message;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('t_hash')){
                        result.error = json.errors['t_hash'];
                    }
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    if(json.hasOwnProperty('message')){
                        result.error = json.message;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
    })
}