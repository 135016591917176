import React, { Component } from 'react';
import Frame from "../../components/frame";
import TopHeader from '../../components/TopHeader';
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import NihaoBNB from "../../contracts/NihaoBNB.json";
import getWeb3 from "../../getWeb3";
// Internationalization
import { withTranslation } from 'react-i18next';
import {withdrawApi,communityDetailsApi,missingTransactionApi,networkSettingApi} from '../../utils';
const defaultAmountArr = ['0.5','1','2','3','5'];


class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:true,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        userInfo:{},
        TotalBonus:0,
        totalDeposits:0,
        networkMain:false,
        YourAddress: '-',
        YourAddressfull: null,
        amountBNB:null,
        adminFee:null,
        adminFee_percentage:5,
        reinvest_percentage:0,
        withdrwal_percentage:0,
        communityLevels:[],
        sponsorList:[],
        poolDepositTotal:0,
        poolDeposit:0,
        poolQualifierCount:0,
        globalInfo:{
          user_count: 0,
          withdraw_amount:0,
          investment_amount:0
        },
        userDetails:{
          wallet_balance:0,
          upline_income:0,
          downline_income:0,
          boardIncome:0,
          pool_1_income:0,
          pool_2_income:0,
          binaryIncome:0,
          sponsorIncome:0,
          totalIncome:0,
          user_withdraw:0,
          user_investment:0,
          userReferredBy:'-',
          userReferredByFull:'-',
          maxUplineIncomeLevel:0,
          maxDownlineIncomeLevel:0,
          maxDownlineIncomeLevel:0,
          upline_percentage:0,
          downline_percentage:0,
        },
        communityDetails:{
          main_user: null,
          upline_list:[],
          downline_list:[]
        },
        withdrawRule:{
          reinvestment_percentage:0,
          withdraw_percentage:0
        },
        pool_bonus_log:{
          bonus_type:null,
          bonus_date:null,
          pool_1_peruser_amount:0,
          pool_1_total_bonus:0,
          pool_1_count:0,
          pool_2_peruser_amount:0,
          pool_2_total_bonus:0,
          pool_2_count:0
        },
        is_pool_1:0,
        is_pool_2:0,
        loadingWithdrawl:false,
        withdrawlAmount:0,
        captchaValueWithdrawal:null,
        loadingMissingTrsn:false,
        transaction_hash:'',
        loadingNSetting:false,
        default_leg:'',
      }
    }

    componentDidMount = async () => {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
  
        // Use web3 to get the user's accounts.
        setInterval(async()=>{
          const accounts = await web3.eth.getAccounts();
         
          if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          //alert(networkId);
          //if(networkId !== 56) throw "Please connect Mainnet"; 
          const deployedNetwork = NihaoBNB.networks[networkId];
          const instance = new web3.eth.Contract(
            NihaoBNB.abi,
            deployedNetwork && deployedNetwork.address,
          );   
          
          this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          }
       },1000);
       
      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false});
        console.error(error);
      }
    };

    fetchData = async() => {
      const { accounts, contract } = this.state;

      console.log(this.state.contractAddress);

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      

      this.setState({ 
        //networkMain:true,
        YourAddress:YourAddress,
        YourAddressfull:YourAddressfull,
        //loading:false
      });
      this.communityDetailsApi();
    }

    communityDetailsApi = async()=>{
      if(!this.state.accounts){
        return false;
      }
      const data = await communityDetailsApi(this.state.accounts);
      //const data = await communityDetailsApi('0x16eee4aea83f57b710bb29c3a406fe5b1b12892c');
      //const data = await communityDetailsApi('TWC8GqmGNkW8m5gYBz1bYKx58qxM8qzdzf');
      //const data = await communityDetailsApi('TEwD1YadPF1ef388xbPwuCWFNGLFDXcJYp');
      console.log('communityDetailsApi',data);
      if(data.status){
        this.setState(({globalInfo})=>
          ({globalInfo : {...globalInfo,
            user_count: data.data.systemUserCount,
            withdraw_amount:data.data.systemWithdraw,
            investment_amount:data.data.systemInvest
          }})
        );
        let userReferredBy = data.data.userReferredBy;
        if(userReferredBy == 'admin'){
          
        }else{
          userReferredBy = data.data.userReferredBy.slice(0, 5) + '...' +data.data.userReferredBy.slice(data.data.userReferredBy.length-5, data.data.userReferredBy.length);
        }
        this.setState(({userDetails})=>
          ({userDetails : {...userDetails,
            wallet_balance:data.data.walletBalance,
            upline_income:data.data.uplineIncome,            
            downline_income:data.data.downlineIncome,
            boardIncome:data.data.boardIncome,
            pool_1_income:data.data.pool_1_income,
            pool_2_income:data.data.pool_2_income,
            binaryIncome:data.data.binaryIncome,
            sponsorIncome:data.data.sponsorIncome,
            totalIncome:data.data.totalIncome,
            user_withdraw:data.data.userWithdraw,
            user_investment:data.data.userDeposit,
            userReferredByFull:data.data.userReferredBy?data.data.userReferredBy:null,
            userReferredBy:data.data.userReferredBy ? userReferredBy :null,
            maxUplineIncomeLevel:data.data.maxUplineIncomeLevel,
            maxDownlineIncomeLevel:data.data.maxDownlineIncomeLevel,
            maxDownlineIncomeLevel:data.data.maxDownlineIncomeLevel,
            upline_percentage:data.data.upline_percentage,
            downline_percentage:data.data.downline_percentage,
          }})
        );
        this.setState(({communityDetails})=>
          ({communityDetails : {...communityDetails, 
            main_user: data.data.main_user,
            upline_list:data.data.uplineList,
            downline_list:data.data.downlineList
          }})
        );
        this.setState(({withdrawRule})=>
          ({withdrawRule : {...withdrawRule, 
            reinvestment_percentage: data.data.reinvestment_percentage,
            withdraw_percentage:data.data.withdraw_percentage
          }})
        );
        
        if(data.data.pool_bonus_log){
          this.setState(({pool_bonus_log})=>
            ({pool_bonus_log : {...pool_bonus_log, 
              bonus_type:data.data.pool_bonus_log.bonus_type,
              bonus_date:data.data.pool_bonus_log.bonus_date,
              pool_1_peruser_amount:data.data.pool_bonus_log.pool_1_peruser_amount,
              pool_1_total_bonus:data.data.pool_bonus_log.pool_1_total_bonus,
              pool_1_count:data.data.pool_bonus_log.pool_1_count,
              pool_2_peruser_amount:data.data.pool_bonus_log2.pool_2_peruser_amount,
              pool_2_total_bonus:data.data.pool_bonus_log2.pool_2_total_bonus,
              pool_2_count:data.data.pool_bonus_log2.pool_2_count
            }})
          );
        }
        this.setState({is_pool_1:data.data.is_pool_1,is_pool_2:data.data.is_pool_2,
          default_leg:data.data.default_leg,loading:false});
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }
  
    refreshApiData = async() => {
      this.setState({loading:true},()=>{
        this.communityDetailsApi();
      });
    }

    doJoinNow = async () => {
      //const weiValue = this.state.web3.utils.toWei('1', 'ether');
      //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
      const { accounts, contract } = this.state;
      let sponsor_address = this.state.sponsor_address;
      let userInfo = await contract.methods.users(accounts).call();
      if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
        sponsor_address = userInfo.referrer;
      }
      else if(!sponsor_address){
        this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }

      if(!this.state.amountBNB){
        this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }
      
      let balance = await this.state.web3.eth.getBalance(this.state.accounts);
      let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
      let amountBNB = this.state.amountBNB;
      if(sponsor_address){
        if(balanceEthVal >= amountBNB){
          try {
            this.setState({loading:true});
            this.state.contract.events.NewDeposit((err, eventResult) => {
              console.log('eventResult',eventResult);
              if (!err) {
                let uerAddress = this.state.accounts;
                if(eventResult.returnValues[0] == uerAddress){
                  this.fetchData();
                  this.setState({loading:false});
                  this.props.enqueueSnackbar("Joined Successfully!",{ variant: 'success' });
                }              
              }else{
                console.log(err);
                this.setState({loading:false});
                this.props.enqueueSnackbar('Some Network Error Occurred!');
              }
            });

            let weiValue = this.state.web3.utils.toWei(amountBNB, 'ether');
            let invest = await this.state.contract.methods.invest(sponsor_address).send(
              {
                from: this.state.accounts,
                value:weiValue
              }
            );
            console.log(invest);
          }
          catch(err) {
            this.setState({loading:false});
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }
        }else{
          this.setState({loading:false});
          this.props.enqueueSnackbar("Insufficient BNB Balance!",{ variant: 'error' });          
        }
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
      }
    }

    doWithdrawal = async () => {
      if(!this.state.withdrawlAmount){
        this.props.enqueueSnackbar("Withdrawl Amount is required!",{ variant: 'error' });
        this.setState({withdrawlAmountError:true});
        return false;
      }
  
      if(this.state.withdrawlAmount < 0.05){
        this.props.enqueueSnackbar("Min Withdrawal 0.05 BNB!",{ variant: 'error' })
        this.setState({withdrawlAmountError:true});
        return false;
      }
  
      if(this.state.userDetails.wallet_balance < 0.05){
        this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' });
        return false;
      }

      // if(!this.state.captchaValueWithdrawal){
      //   this.props.enqueueSnackbar("Invalid Captcha!",{ variant: 'error' });
      //   return false;
      // }
  
      try {
        this.setState({loadingWithdrawl:true});
        const data = await withdrawApi(this.state.accounts,this.state.withdrawlAmount,this.state.captchaValueWithdrawal);
        //console.log('doWithdrawl',data);
        if(data.status){
          this.setState({withdrawlAmount:0, loadingWithdrawl:false});
          this.communityDetailsApi();
          this.props.enqueueSnackbar("Congratulation! Your Withdrawl made successfully!",{ variant: 'success' })
        }else{
          let defaultError = "Transaction failed!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
          this.setState({loadingWithdrawl:false});
        }
      } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loadingWithdrawl:false});
        let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';        
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }           
    }

    doMissingTrsn = async(e) => {
      if(!this.state.transaction_hash){
        this.props.enqueueSnackbar("Transaction Hash is required!",{ variant: 'error' });
        this.setState({transaction_hashError:true});
        return false;
      }
  
      try {
        this.setState({loadingMissingTrsn:true, loading:true});
        const data = await missingTransactionApi(this.state.transaction_hash);
        //const data = await missingTransactionApi(this.state.transaction_hash);
        //console.log('doMissingTrsn',data);
        if(data.status){
          this.setState({transaction_hash:'', loadingMissingTrsn:false, loading:false});
          this.props.enqueueSnackbar(data.message,{ variant: 'success' })
        }else{
          let defaultError = "Transaction failed!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          if(data.hasOwnProperty('message')){
            defaultError = data.message;
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
          this.setState({loadingMissingTrsn:false, loading:false});
        }
      } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loadingMissingTrsn:false, loading:false});
        let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';        
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
    }

    doNSetting = async(e) => {
      if(!this.state.default_leg){
        this.props.enqueueSnackbar("Default leg is required!",{ variant: 'error' });
        this.setState({default_legError:true});
        return false;
      }
  
      try {
        this.setState({loadingNSetting:true, loading:true});
        //const data = await networkSettingApi('admin',this.state.default_leg);
        const data = await networkSettingApi(this.state.accounts,this.state.default_leg);
        //console.log('doNSetting',data);
        if(data.status){
          this.setState({transaction_hash:'', loadingNSetting:false, loading:false});
          this.props.enqueueSnackbar(data.message,{ variant: 'success' })
        }else{
          let defaultError = "Transaction failed!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          if(data.hasOwnProperty('message')){
            defaultError = data.message;
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
          this.setState({loadingNSetting:false, loading:false});
        }
      } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loadingNSetting:false, loading:false});
        let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';        
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
    }

    render() {
      const {classes, t} = this.props;
      let tableData = [];
      if(this.state.communityDetails.upline_list.length>0){
        let upline_list = this.state.communityDetails.upline_list;
        //upline_list.sort();
        upline_list.sort((a, b) => b.level>a.level? 1 : -1);
        upline_list.map(function(val, index){
          val.type = 'upline';
          tableData.push(val);
        })        
      }

      if(this.state.communityDetails.main_user){
        let main_user = this.state.communityDetails.main_user;
        main_user.type = 'main_user';
        tableData.push(main_user);
      }

      // if(this.state.communityDetails.downline_list.length>0){
      //   this.state.communityDetails.downline_list.map(function(val, index){
      //     val.type = 'downline';
      //     tableData.push(val);
      //   })
      // }

      let this2 = this;

      let pool_1_bg_class = 'Personal_Details_inner red_bg';
      let pool_2_bg_class = 'Personal_Details_inner red_bg';
      if(this.state.is_pool_1 == 1){
        pool_1_bg_class = 'Personal_Details_inner green_bg';
      }
      if(this.state.is_pool_2 == 1){
        pool_2_bg_class = 'Personal_Details_inner green_bg';
      }

      return (
        <Frame withFooter={false}>
          <TopHeader isHome={true} address={this.state.accounts} />

          {/* <div class="modal fade" id="modal_show">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
              
                <div class="modal_bg_img">
                <div class="modal_content">
                  <button type="button" class="modal_close" data-dismiss="modal">
                    <img class="img-responsive" src="/img/Close-icon.png" alt="" />
                  </button>

                    <img class="img-fluid" src="/img/nihao-bnb4.jpeg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          
          <div className="pb_30 pt_30">
            <div className="container">
              <div className="banner_text">
                <h1>{t('header.Heading1')}</h1>
                <p>{t('header.Heading1_Content')}</p>
                {/* <a className="top_btn top_btn_padding" href="/details">{t('mainContent.Details')}</a>
                <a target="_blank" className="top_btn" href={`https://v2.nihaobnb.com/app/en/board-tree/${this.state.accounts}`}>{t('mainContent.My_board')}</a> */}
              </div>
            </div>
          </div>

          {this.state.loading ? (
            <div class="wrapper">
              <div class="flex">
                <span class="circle-1"></span>
                <span class="circle-2"></span>
                <span class="circle-3"></span>
                </div>
            </div>
          ) : (null)}

          <div className="pb_30 pt_30">
            <div className="container">
              <div className="dark_box">
                <div className="all_heading">
                  <span className="top_line"></span>
                  <h3>{t('mainContent.Personal_Details')} <span className="float-right">{this.state.YourAddress}</span></h3>
                </div>
                <div className="row cus_row">
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Total_Deposit')}</h4>
                      <h5>{parseFloat(this.state.userDetails.user_investment).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Total_Withdrawn')}</h4>
                      <h5>{parseFloat(this.state.userDetails.user_withdraw).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-12">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Referred_By')}</h4>
                      <h5>
                        <CopyToClipboard text={`${this.state.userDetails.userReferredByFull? this.state.userDetails.userReferredByFull : '-'}`}
                          onCopy={() => this.props.enqueueSnackbar(t('mainContent.Copied_Successfully'),{ variant: 'info' })}>
                          <div style={{cursor:'pointer'}}>{this.state.userDetails.userReferredBy ? this.state.userDetails.userReferredBy : '-'}</div>
                        </CopyToClipboard>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb_30 pt_30">
            <div className="container">
              <div className="md_container">
                <h3 className="heading_text">{t('mainContent.Join_Us_Heading')}</h3>
                <div className="box_line"></div>
                <div className="padding_40">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-8 col-lg-8 col-sm-8 form-group">
                        {(this.state.userDetails.userReferredByFull && this.state.userDetails.userReferredByFull!='-') ?(
                          <input className="cus_input" type="text" placeholder={t('mainContent.Sponsor_Address')}
                            readOnly
                            value={this.state.userDetails.userReferredByFull}
                            onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                          />
                        ):(
                          <input className="cus_input" type="text" placeholder={t('mainContent.Sponsor_Address')}
                            value={this.state.sponsor_address}
                            onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                          />
                        )}
                      </div>
                      <div className="col-md-4 col-lg-4 col-sm-4">
                        <button onClick={this.doJoinNow} className="top_btn btn-block">{t('mainContent.Join_Now')}</button>
                      </div>
                    </div>
                  </div>
                  <ul className="trx_btn">
                    {defaultAmountArr.map((amount,key) => {return(
                      <li key={key}>
                        <button className={this.state.amountBNB==amount?'active':'inactive'} 
                          onClick={e => this.setState({amountBNB:amount})}>
                          {amount+' '+t('mainContent.BNB')}
                        </button>
                      </li>
                    )
                    })}
                  </ul>
                </div>
                <div className="box_line"></div>
                <h5 className="Missing_transaction_text">{t('mainContent.Enter_Transaction_Hash_Verify')}</h5>

                <div id="Missing_transaction_text" >
                  <div className="sm_container pb_20">
                    <br />
                    <div className="">
                      <div className="row">                        
                        <div className="col-md-8 col-lg-8 col-sm-8">
                          <div className="form-group">
                            <input className="cus_input" type="text" placeholder={t('mainContent.Enter_Transaction_Hash')}
                              onChange={e => this.setState({transaction_hash:e.target.value})}
                              value={this.state.transaction_hash}
                              error={this.state.transaction_hashError}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-4">
                          <button className="top_btn btn-block" disabled={this.state.loadingMissingTrsn} onClick={e => this.doMissingTrsn(e)}>{t('mainContent.Submit')}</button>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div> 
                <div className="box_line"></div>
              </div>
            </div>
          </div>

          <div className="pb_30 pt_30">
            <div className="container">
              <div className="dark_box">
                <div className="all_heading">
                  <span className="top_line"></span>
                  <h3>{t('mainContent.Community_Details')}</h3>
                </div>
                <div className="row cus_row">
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Total_Income')}</h4>
                      <p>{t('mainContent.Total_Income_Text')}</p>
                      <h5>{parseFloat(this.state.userDetails.totalIncome).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Binary_Income')}</h4>
                      <p>{t('mainContent.Binary_Income_Text')}</p>
                      <h5>{parseFloat(this.state.userDetails.binaryIncome).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Board_Income')}</h4>
                      <p>{t('mainContent.Board_Income_Text')}</p>
                      <h5>{parseFloat(this.state.userDetails.boardIncome).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div> 
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Upline_Income')}</h4>
                      <p>{t('mainContent.Upline_Income_Text')}</p>
                      <h5>{parseFloat(this.state.userDetails.upline_income).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb_30 pt_30">
            <div className="container">
              <div className="dark_box">
                <div className="all_heading">
                  <span className="top_line"></span>
                  <h3>{t('mainContent.Pool_Details')}</h3>
                </div>
                <div className="row cus_row">                  
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className={pool_1_bg_class}>
                      <h4>{t('mainContent.Pool_Income_1')}</h4>                      
                      <h5>{parseFloat(this.state.userDetails.pool_1_income).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className={pool_1_bg_class}>
                      <h4>{t('mainContent.Distributed_Amount')}</h4>
                      <h5>{parseFloat(this.state.pool_bonus_log.pool_1_total_bonus).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className={pool_1_bg_class}>
                      <h4>{t('mainContent.Total_Member')}</h4>
                      <h5>{parseFloat(this.state.pool_bonus_log.pool_1_count).toFixed(0)} </h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className={pool_1_bg_class}>
                      <h4>{t('mainContent.Individual_Amount')}</h4>
                      <h5>{parseFloat(this.state.pool_bonus_log.pool_1_peruser_amount).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className={pool_2_bg_class}>
                      <h4>{t('mainContent.Pool_Income_2')}</h4>
                      <h5>{parseFloat(this.state.userDetails.pool_2_income).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className={pool_2_bg_class}>
                      <h4>{t('mainContent.Distributed_Amount')}</h4>
                      <h5>{parseFloat(this.state.pool_bonus_log.pool_2_total_bonus).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className={pool_2_bg_class}>
                      <h4>{t('mainContent.Total_Member')}</h4>
                      <h5>{parseFloat(this.state.pool_bonus_log.pool_2_count).toFixed(0)}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className={pool_2_bg_class}>
                      <h4>{t('mainContent.Individual_Amount')}</h4>
                      <h5>{parseFloat(this.state.pool_bonus_log.pool_2_peruser_amount).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="pb_20 pt_20">
            <div className="container">
              <div className="md_container">
                <h3 className="heading_text">{t('mainContent.Repurchased_package')}</h3>
                <div className="box_line"></div>
                <div className="padding_40">
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group mb_20">
                        <label className="white_label">{t('mainContent.Available')} {t('mainContent.BNB')} ({this.state.adminFee_percentage} % {t('mainContent.Admin_Fee')}: {this.state.adminFee})</label>
                        <input className="cus_input" type="text" readOnly value={parseFloat(this.state.userDetails.wallet_balance).toFixed(4)} />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group">
                        <label className="white_label">{t('mainContent.Withdrawal')} {t('mainContent.BNB')}({t('mainContent.Min')}:0.05 {t('mainContent.BNB')})</label>
                        <input className="cus_input" type="text" 
                          onChange={e => this.setState({withdrawlAmount:e.target.value})}
                          value={this.state.withdrawlAmount}
                        />
                      </div>
                  </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group mb_20">
                        <label className="white_label">({t('mainContent.Reinvestment_Amount')}) ({t('mainContent.BNB')})</label>
                        <input className="cus_input" type="text" readOnly 
                          value={parseFloat(this.state.withdrawRule.reinvestment_percentage*this.state.withdrawlAmount/100).toFixed(4)} />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group mb_20">
                        <label className="white_label">{t('mainContent.Withdrawable_Amount')} ({t('mainContent.BNB')})</label>
                        <input className="cus_input" type="text" readOnly
                          value={parseFloat(this.state.withdrawRule.withdraw_percentage*this.state.withdrawlAmount/100).toFixed(4)} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb_20">
                    <p className="white_label mb-0">{t('mainContent.Reinvestment_percentage')}: <strong>{this.state.withdrawRule.reinvestment_percentage}%</strong></p>
                    <p className="white_label">{t('mainContent.Withdrawable_percentage')}: <strong>{this.state.withdrawRule.withdraw_percentage}% </strong></p>
                  </div>
                  <div className="text-center mt_20">
                    <button onClick={this.doWithdrawal} className="top_btn">{t('mainContent.Withdrawal')}</button>
                  </div>
                </div>
                {/* <div class="alert alert-warning" role="alert">
                  NihaoBNB is under scheduled maintenance. During the period, withdrawals may be delayed. We request you to bear with us. 
                  Apologies for any inconvenience caused. 
                </div> */}
                <div style={{fontSize:14,marginBottom:10,textAlign:'center',color:'#ffe738',fontStyle:'italic'}}>{t('mainContent.Withdrawal_Note')}</div>
                <div className="box_line"></div>
              </div>
            </div>
          </div>

          {/* <div className="pb_30 pt_30">
            <div className="container">
              <div className="dark_box">
                <div className="all_heading">
                  <span className="top_line"></span>
                  <h3>Global Details </h3>
                </div>
                <div className="row cus_row">
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Total')} {t('mainContent.Packages')}</h4>
                      <h5>{this.state.globalInfo.user_count}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Total')} {t('mainContent.Invested')}</h4>
                      <h5>{parseFloat(this.state.globalInfo.investment_amount).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-12">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Total')} {t('mainContent.Withdrawal')}</h4>
                      <h5>{parseFloat(this.state.globalInfo.withdraw_amount).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          

          <div className="pb_30 pt_30">
            <div className="container">
              <div className="md_container">
                <h3 className="heading_text">{t('mainContent.Upline')}</h3>
                <div className="box_line"></div>
                <div className="padding_40">
                  <div className="table-responsive">
                    <table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>{t('mainContent.Level')}</th>
                          <th>{t('mainContent.User_ID')}</th>
                          <th>{t('mainContent.Investment')}</th>
                          <th>{t('mainContent.Income')}</th>
                        </tr>
                      </thead>
                      <tbody>                        
                        {this.state.communityDetails.main_user ? (
                          tableData.length>0 ? (
                            tableData.map(function(val, index){
                              let class_name = 'lebel_'+val.level;
                              if(val.level == 0){
                                class_name = 'current_user';
                              }
                              return (
                                <tr key={`cl${index}`} className={class_name}>
                                  <td style={{width:'120px'}}>Level {val.level}</td>
                                  <td style={{width:'50%'}}>{val.username}</td>
                                  <td>{parseFloat(val.total_investment).toFixed(4)} {t('mainContent.BNB')}</td>
                                  <td>{val.income} {t('mainContent.BNB')}</td>
                                </tr>
                              )
                            })
                          ):(null)
                        ) : (
                        <tr>
                          <td colSpan="4" className="text-center">{t('mainContent.No_Data_Available')}</td>
                        </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="box_line"></div>
              </div>
            </div>
          </div>

          <div className="pb_30 pt_30" style={{display:'none'}}>
            <div className="container">
              <div className="md_container">
                <h3 className="heading_text">{t('mainContent.My_direct_tree_team')}</h3>
                <div className="box_line"></div>
                <div className="padding_40">
                  <div className="table-responsive">
                    <table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th> {t('mainContent.Level')} </th>
                          <th> {t('mainContent.Count')} </th>
                          <th> {t('mainContent.Invest_Amount')} </th>
                          <th> {t('mainContent.Percentage_Amount')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.sponsorList.length ? (
                          this.state.sponsorList.length>0 ? (
                            this.state.sponsorList.map(function(val, index){
                              let class_name = 'lebel_'+val.level;
                              if(val.level == 0){
                                class_name = 'current_user';
                              }
                              return (
                                <tr key={`sl${index}`} className={class_name}>
                                  <td style={{width:'120px'}}>
                                  {t('mainContent.Level')}-{val.level}
                                  </td>
                                  <td>{val._noOfUser}</td>
                                  <td>{val._investment} {t('mainContent.BNB')}</td>
                                  <td>{val.percentage_amount} {t('mainContent.BNB')} 
                                    {
                                      val.statusEn?(
                                          <span>
                                            (
                                            <img style={{height:30,width:30,padding:5,marginBottom:5}} src="img/cross.png" />
                                            {/* {t('mainContent.Unqualified')} */}
                                            )
                                          </span>
                                      ):(
                                        <span>
                                          (
                                          <img style={{height:30,width:30,padding:5,marginBottom:5}} src="img/tick.png" />
                                          )
                                        </span>
                                      )
                                    }
                                  </td>
                                </tr>
                              )
                            })
                          ):(null)
                        ) : (
                        <tr>
                          <td colSpan="4" className="text-center">{t('mainContent.No_Data_Available')}</td>
                        </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="box_line"></div>
              </div>
            </div>
          </div>

          <div className="pb_30 pt_30">
            <div className="container">
              <div className="dark_box">
                <div className="all_heading">
                  <span className="top_line"></span>
                  <h3>{t('mainContent.Network_Setting')}</h3>
                </div>
                <div className="row cus_row">
                  <div className="col-md-8 col-lg-8 col-sm-8">
                    <div className="form-group">
                      <select className="cus_select" name="default_leg"
                        onChange={e => this.setState({default_leg:e.target.value})}
                        value={this.state.default_leg}
                        >
                        <option value="L">{t('mainContent.LEFT')}</option>
                        <option value="R">{t('mainContent.RIGHT')}</option>
                        <option value="NA">{t('mainContent.NOT_APPLICABLE')}</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <button className="top_btn btn-block" disabled={this.state.loadingNSetting} onClick={e => this.doNSetting(e)}>{t('mainContent.Submit')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb_30 pt_30">
            <div className="container">
              <div className="dark_box">
                <div className="all_heading">
                  <span className="top_line"></span>
                  <h3>{t('mainContent.Your_Referral_Link')}</h3>
                </div>
                <h4 className="referal_text word-break">https://v2.nihaobnb.com/{this.state.YourAddress}</h4>
                <div className="text-center mt_20">
                  <CopyToClipboard text={`https://v2.nihaobnb.com/${this.state.YourAddressfull}`}
                    onCopy={() => this.props.enqueueSnackbar(t('mainContent.Copied_Successfully'),{ variant: 'info' })
                    }>
                    <button className="top_btn">{t('mainContent.Copy_Link')}</button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="pb_30 pt_30">
            <div className="container">
                <ul className="pdf_ul">
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_English.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.English_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_French.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.French_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_German.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.German_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Indonesian.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.Indonesian_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Korean.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.Korean_PDF')}</span>                      
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Russian.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.Russian_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Tagalog.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.Tagalog_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Japanese.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.Japanese_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Vietnamese.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.Vietnamese_PDF')}</span>
                    </a>
                  </li>
                </ul>
            </div>
          </div> */}
          
          <div className="pb_30 pt_30">
            <div className="container">
              <div className="">
              <div className="box_line mb_40"></div>
                <h4 className="referal_text word-break">
                  <a className="footer_contact text-center" 
                    href="https://bscscan.com/address/0x6e7f15b48cf7418a676169f249a98ffa7b1c26dc" target="_blank">
                    {t('mainContent.Contract')}: 0x6e7f15b48cf7418a676169f249a98ffa7b1c26dc
                    </a>
                </h4>                
              </div>
            </div>
          </div>

          <div>
            <footer className="pb_60 pt_30">
              <div className="container">
                <div className="box_line mb_40"></div>
                <div className="md_container">
                  <img className="footer_logo " src="img/dark_logo.png" alt="" />
                  <p className="copy_text">&copy; {t('mainContent.All_Rights_Reserved')} </p>
                </div>
                
              </div>
            </footer>
          
            <a href="javascript:" id="return-to-top">{t('mainContent.Go_To_Top')}</a>

            <div className="overlay">
              <div className="overlayDoor"></div>
              <div className="overlayContent">
                  <div className="">
                      <img src="img/light_logo.png?fd" alt="" />
                      <p>{t('mainContent.Loading.....')}</p>
                  </div>
              </div>
            </div>
          </div>
        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }


  export default withRouter(withTranslation()(withStyles(useStyles)(withSnackbar(Home))));
  //export default Home;