import React, { Component } from 'react';

import Footer from "./footer";


class Frame extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
        const {withFooter } = this.props;
      return (
        <div>
          
      
         {this.props.children}

        {withFooter &&  <Footer></Footer>}
        

        </div>
      );
    }
  }

  export default Frame;