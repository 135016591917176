import React, {Suspense} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Details from "./pages/details/details";
import Home from "./pages/home/home";
import './App.css';
import './i18n';

class App extends React.Component {

  render() {
  return (
    <div className="App">
      {/* <Suspense fallback={<div></div>}>  */}
        <Router>
      <div>
        

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/details">
            <Details></Details>
          </Route>
          <Route exact path="/:referral?">
            <Home></Home>
          </Route>
         
        </Switch>
      </div>
    </Router>
    {/* </Suspense> */}
    </div>
  );
  }
}

export default App;
