import React,{useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';



function TopHeader(props){
  const {isHome,address} = props;

  const { t, i18n } = useTranslation();

  let DefaultLan = localStorage.getItem('DefaultLan','中国');
  if(!DefaultLan){
    DefaultLan = '中国';
  }
  let DefaultStrLan = localStorage.getItem('DefaultStrLan','zh');
  if(!DefaultStrLan){
    DefaultStrLan = 'zh';
  }
  let DefaultLanIcon = localStorage.getItem('DefaultLanIcon','img/language/zh.svg');
  if(!DefaultLanIcon){
    DefaultLanIcon = 'img/language/zh.svg';
  }
  const [currentLanguage, setCurrentLanguage] = useState(DefaultLan);
  const [currentSrtLanguage, setCurrentSrtLanguage] = useState(DefaultStrLan);
  const [currentLanguageIcon, setCurrentLanguageIcon] = useState(DefaultLanIcon);

  useEffect(() => {
    i18n.changeLanguage(DefaultStrLan);
  },[DefaultStrLan]);


  const changeLanguage = function(event){
      if(event.target.closest("a")){
        let el = event.target.closest("a");
        let lang = el.getAttribute("data-lang");
        i18n.changeLanguage(lang);

        if(lang == "en"){
          saveLanguage("en", "English", "img/language/en.svg");

        }else if(lang == "ru"){
          saveLanguage("ru", "Русский", "img/language/ru.svg");

        }else if(lang == "zh"){
          saveLanguage("zh", "中国", "img/language/zh.svg");

        }else if(lang == "es"){
          saveLanguage("es", "Español", "img/language/es.svg");


        }else if(lang == "id"){
          saveLanguage("id", "Indonesian", "img/language/id.svg");

        }else if(lang == "hi"){
          saveLanguage("hi", "हिंदी", "img/language/hi.svg");

        }else if(lang == "ar"){
          saveLanguage("ar", "عربى", "img/language/ara.svg");


        }else if(lang == "vi"){
          saveLanguage("vi", "Việt Nam", "img/language/vi.svg");
        }
        else if(lang == "it"){
          saveLanguage("it", "Italian", "img/language/it.svg");
        }
        else if(lang == "pt"){
          saveLanguage("pt", "português", "img/language/pt.svg");
        }
        else if(lang == "ja"){
          saveLanguage("ja", "日本", "img/language/ja.svg");
          
        }else if(lang == "ro"){
          saveLanguage("ro", "Română", "img/language/ro.svg");
        }else if(lang == "de"){
          saveLanguage("de", "Deutsch", "img/language/de.svg");
        }
      }

  }

  const saveLanguage = function(str, lang, iconLink){

          setCurrentSrtLanguage(str);
          setCurrentLanguage(lang);
          setCurrentLanguageIcon(iconLink);

          localStorage.setItem('DefaultLan',lang);
          localStorage.setItem('DefaultStrLan', str);
          localStorage.setItem('DefaultLanIcon',iconLink);
  }
  return (
  <header>
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-3 col-12">
            <a href="/" className="disp_inline">
              <img className="logo" src="img/light_logo.png" alt="" />
            </a>
           
          </div>

          <div className="col-md-9 col-sm-9 col-12 text-right">
            {!isHome?(
              <>
               <ul class="cus_scroll topmenu-scroll">
                  <li><a href="/" className="top_btn top_btn_padding"> {t('topMenu.Home')}</a></li>
                  <li><a target="_blank" className="top_btn top_btn_padding" href={`https://v2.nihaobnb.com/app/en/board-tree/${address}`}>{t('mainContent.My_board')}</a></li>
                  <li><a target="_blank" className="top_btn" href={`https://v2.nihaobnb.com/app/en/binary-tree/${address}`}>{t('mainContent.Binary_Tree')}</a></li>
                </ul>
              </>
            ):(
              <>
              <ul class="cus_scroll topmenu-scroll">
                <li><a className="top_btn top_btn_padding" href="/details">{t('mainContent.Details')}</a></li>
                <li><a target="_blank" className="top_btn top_btn_padding" href={`https://v2.nihaobnb.com/app/en/board-tree/${address}`}>{t('mainContent.My_board')}</a></li>
                <li><a target="_blank" className="top_btn" href={`https://v2.nihaobnb.com/app/en/binary-tree/${address}`}>{t('mainContent.Binary_Tree')}</a></li>
              </ul>
                
              </>
            )}
            <div className="language_abs disp_inline">
              <div className="dropdown">
                <button className="dropbtn"><img className="main_lang_flag" alt="" src={currentLanguageIcon} />  {currentLanguage}</button>
                <div className="dropdown-content" onClick={changeLanguage}>
                  <a data-lang="en" style={{display: (currentSrtLanguage == 'en') ? "none" : "block"}} href="javascript:void"><img alt="" src="img/language/en.svg" />  English</a>
                  <a data-lang="zh" style={{display: (currentSrtLanguage == 'zh') ? "none" : "block"}} href="javascript:void"><img alt="" src="img/language/zh.svg" />  中国</a>
                  <a data-lang="es" style={{display: (currentSrtLanguage == 'es') ? "none" : "block"}} href="javascript:void"><img alt="" src="img/language/es.svg" />  Español</a>
                  <a data-lang="ru" style={{display: (currentSrtLanguage == 'ru') ? "none" : "block"}} href="javascript:void"><img alt="" src="img/language/ru.svg" />  Русский</a>
                  <a data-lang="it" style={{display: (currentSrtLanguage == 'it') ? "none" : "block"}} href="javascript:void"><img alt="" src="img/language/it.svg" />  Italian</a>
                  <a data-lang="pt" style={{display: (currentSrtLanguage == 'pt') ? "none" : "block"}} href="javascript:void"><img alt="" src="img/language/pt.svg" />  português</a>
                  <a data-lang="ja" style={{display: (currentSrtLanguage == 'ja') ? "none" : "block"}} href="javascript:void"><img alt="" src="img/language/ja.svg" />  日本</a>
                  <a data-lang="ro" style={{display: (currentSrtLanguage == 'ro') ? "none" : "block"}} href="javascript:void"><img alt="" src="img/language/ro.svg" />  Română</a>
                  <a data-lang="de" style={{display: (currentSrtLanguage == 'de') ? "none" : "block"}} href="javascript:void"><img alt="" src="img/language/de.svg" />  Deutsch</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>    
  )
}

export default  TopHeader;