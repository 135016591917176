import React, { Component } from 'react';
import Frame from "../../components/frame";
import TopHeader from '../../components/TopHeader';
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import NihaoBNB from "../../contracts/NihaoBNB.json";
import getWeb3 from "../../getWeb3";
// Internationalization
import { withTranslation } from 'react-i18next';
import {dataDetailsApi} from '../../utils';

class Details extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:true,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        userDetails:{
          leftTeam:0,
          rightTeam:0,
          leftDirect:0,
          rightDirect:0,
          walletBalance:0,
          uplineIncome:0,
          boardIncome:0,
          binaryIncome:0,
          sponsorIncome:0,
          totalPackage:0,
          totalIncome:0
        },
        subscriptionList:null,
        withdrawList:null,
        incomeList:null,
        binaryPayoutLog:null,
        remaining_left_payout:0,
        remaining_right_payout:0,
        left_user_count:0,        
        right_user_count:0,
        left_subscription_count:0,
        right_subscription_count:0,
      }
    }

    componentDidMount = async () => {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
  
        // Use web3 to get the user's accounts.
        setInterval(async()=>{
          const accounts = await web3.eth.getAccounts();
         
          if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          //alert(networkId);
          //if(networkId !== 56) throw "Please connect Mainnet"; 
          const deployedNetwork = NihaoBNB.networks[networkId];
          const instance = new web3.eth.Contract(
            NihaoBNB.abi,
            deployedNetwork && deployedNetwork.address,
          );   
          
          this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          }
       },1000);

       

      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false});
        console.error(error);
      }
    };

    fetchData = async() => {
      const { accounts, contract } = this.state;

      console.log(this.state.contractAddress);

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      
      this.setState({
        YourAddress:YourAddress,
        YourAddressfull:YourAddressfull,
        //loading:false
      });     
      this.dataDetailsApi();
    }

    paginate = (
      totalItems,
      currentPage = 1,
      pageSize = 10,
      maxPages = 10
    ) => {
      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize);
  
      // ensure current page isn't out of range
      if (currentPage < 1) {
          currentPage = 1;
      } else if (currentPage > totalPages) {
          currentPage = totalPages;
      }
  
      let startPage, endPage;
      if (totalPages <= maxPages) {
          // total pages less than max so show all pages
          startPage = 1;
          endPage = totalPages;
      } else {
          // total pages more than max so calculate start and end pages
          let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
          let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
          if (currentPage <= maxPagesBeforeCurrentPage) {
              // current page near the start
              startPage = 1;
              endPage = maxPages;
          } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
              // current page near the end
              startPage = totalPages - maxPages + 1;
              endPage = totalPages;
          } else {
              // current page somewhere in the middle
              startPage = currentPage - maxPagesBeforeCurrentPage;
              endPage = currentPage + maxPagesAfterCurrentPage;
          }
      }
  
      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  
      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
  
      // return object with all pager properties required by the view
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };
    }
  
    getPaginationData = async(url,page,type,current_page,last_page) => {
      if(!url && !page){
        return true;
      }
      this.setState({loading:true});
      this.dataDetailsApi(url,page,type);
    }
  
    refreshApiData = async() => {
      this.setState({loading:true},()=>{
        this.dataDetailsApi();
      });
    }
  
    dataDetailsApi = async(url=null,page=null,type=null)=>{
      if(!this.state.accounts){
        return false;
      }
      const data = await dataDetailsApi(this.state.accounts,url,page,type);
      //const data = await dataDetailsApi('0xa3c86f5dee63aef205e52bc3b00151634dcd7594',url,page,type);
      //console.log('dataDetailsApi',data);
      if(data.status){
        // let totalIncome = parseFloat(data.data.uplineIncome) +
        // parseFloat(data.data.boardIncome) + parseFloat(data.data.binaryIncome);
        this.setState(({userDetails})=>
          ({userDetails : {...userDetails,
            leftTeam:data.data.leftTeam,
            rightTeam:data.data.rightTeam,
            leftDirect:data.data.leftDirect,
            rightDirect:data.data.rightDirect,
            walletBalance:data.data.walletBalance,
            uplineIncome:data.data.uplineIncome,
            boardIncome:data.data.boardIncome,
            binaryIncome:data.data.binaryIncome,
            sponsorIncome:data.data.sponsorIncome,
            totalPackage:data.data.totalPackage,
            totalIncome:data.data.totalIncome
          }})
        );
  
        let subscriptionList = data.data.subscriptionList;
        let subscriptionListPagination = this.paginate(subscriptionList.total,subscriptionList.current_page,subscriptionList.per_page,10);
        subscriptionList.pages = subscriptionListPagination.pages;
  
        let withdrawList = data.data.withdrawList;
        let withdrawListPagination = this.paginate(withdrawList.total,withdrawList.current_page,withdrawList.per_page,10);
        withdrawList.pages = withdrawListPagination.pages;
  
        // let incomeList = data.data.incomeList;
        // let incomeListPagination = this.paginate(incomeList.total,incomeList.current_page,incomeList.per_page,10);
        // incomeList.pages = incomeListPagination.pages;
  
        let binaryPayoutLog = data.data.binaryPayoutLog;
        let binaryPayoutLogPagination = this.paginate(binaryPayoutLog.total,binaryPayoutLog.current_page,binaryPayoutLog.per_page,10);
        binaryPayoutLog.pages = binaryPayoutLogPagination.pages;
        
        this.setState({
          subscriptionList:data.data.subscriptionList,
          withdrawList:data.data.withdrawList,
          //incomeList:data.data.incomeList,
          binaryPayoutLog:data.data.binaryPayoutLog,
          remaining_left_payout:data.data.remaining_left_payout,
          remaining_right_payout:data.data.remaining_right_payout,
          left_user_count:data.data.left_user_count,
          right_user_count:data.data.right_user_count,
          left_subscription_count:data.data.left_subscription_count,
          right_subscription_count:data.data.right_subscription_count,
          loading:false        
        });
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }

    render() {
      const {classes, t} = this.props;
      let this2 = this;
      return (
        <Frame withFooter={false}>
          <TopHeader isHome={false} address={this.state.accounts} />

          {this.state.loading ? (
            <div class="wrapper">
              <div class="flex">
                <span class="circle-1"></span>
                <span class="circle-2"></span>
                <span class="circle-3"></span>
                </div>
            </div>
          ) : (null)}

          <div className="pb_20 pt_60">
              <div className="container">
              <div className="dark_box">
                <div className="row cus_row">
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Total_Bonus')}</h4>
                      <p>{t('mainContent.Amount')}</p>
                      <h5>{parseFloat(this.state.userDetails.totalIncome).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Wallet_Balance')}</h4>
                      <p>{t('mainContent.Amount')}</p>
                      <h5>{parseFloat(this.state.userDetails.walletBalance).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Upline_Bonus')}</h4>
                      <p>{t('mainContent.Net_Profit')} </p>
                      <h5>{parseFloat(this.state.userDetails.uplineIncome).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Shuffle_Bonus')} </h4>
                      <p>{t('mainContent.Net_Profit')} </p>
                      <h5>{parseFloat(this.state.userDetails.boardIncome).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Matching_Bonus')}</h4>
                      <p>{t('mainContent.Net_Profit')} </p>
                      <h5>{parseFloat(this.state.userDetails.binaryIncome).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Your_Subscription')}</h4>
                      <p>{t('mainContent.Amount')}</p>
                      <h5>{parseFloat(this.state.userDetails.totalPackage).toFixed(4)} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  
                </div>
              </div>
              </div>
            </div>

            <div className="pb_30 pt_30">
              <div className="container">
                <div className="md_container">
                  <h3 className="heading_text">{t('mainContent.Subscription_History')}</h3>
                  <div className="box_line"></div>
                  <div className="padding_20">
                    <div className="table-responsive">
                      <table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>{t('mainContent.Total_Amount')} </th>
                            <th>{t('mainContent.Activation_Date')}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.subscriptionList ? (
                          this.state.subscriptionList.data.length>0 ? (
                            this.state.subscriptionList.data.map(function(val, index){                        
                              return (
                                <tr key={`pah${index}`}>
                                  <td className="text-info">{parseFloat(val.total_amount).toFixed(4)} {t('mainContent.BNB')}</td>
                                  <td>{val.created_at}</td>
                                </tr>
                              )
                            })
                          ):(
                            <tr>
                              <td colSpan="2" className="text-center">{t('mainContent.No_Data_Available')}</td>
                            </tr>
                          )
                        ) : (
                        <tr>
                          <td colSpan="4" className="text-center">{t('mainContent.No_Data_Available')}</td>
                        </tr>
                        )}
                        </tbody>
                      </table>
                      {this.state.subscriptionList ? (
                        <div className="text-center">
                          <ul className="table_pagination mb-0">
                            <li  style={{cursor:this.state.subscriptionList.prev_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.subscriptionList.prev_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)}><i class="fa fa-angle-double-left"></i></a>
                            </li>
                            {this.state.subscriptionList.pages.length>0 ? (
                              this.state.subscriptionList.pages.map(function(val, index){
                                let className = "";
                                if(val == this2.state.subscriptionList.current_page){
                                  className = "active";
                                }
                                return (
                                  <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.subscriptionList.current_page?'pointer':'initial'}}>
                                    <a onClick={()=>this2.getPaginationData(null,'subs_page',val,null,null)} >{val}</a>
                                  </li>
                                )
                              })
                            ):(null)}
                            <li style={{cursor:this.state.subscriptionList.next_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.subscriptionList.next_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)} ><i class="fa fa-angle-double-right"></i></a>
                            </li>
                          </ul>
                        </div>
                      ):(null)}
                    </div>
                  </div>
                  <div className="box_line"></div>
                </div>
              </div>
            </div>

            <div className="pb_30 pt_30">
              <div className="container">
                <div className="md_container">
                  <h3 className="heading_text">{t('mainContent.Withdraw_History')} </h3>
                  <div className="box_line"></div>
                  
                  <div className="padding_20">
                    <p className="text-center">{t('mainContent.Available_Balance')}: {parseFloat(this.state.userDetails.walletBalance).toFixed(4)}</p>
                    <div className="table-responsive">
                      <table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th># {t('mainContent.Transaction_Id')}</th>
                            <th>{t('mainContent.Amount_From')}</th>
                            <th>{t('mainContent.Withdrawable_Amount')}</th>
                            <th>{t('mainContent.Reinvestment_AmountD')}</th>
                            <th>{t('mainContent.Remarks')}</th>
                            <th>{t('mainContent.Status')}</th>
                            <th>{t('mainContent.Date')}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.withdrawList ? (
                          this.state.withdrawList.data.length>0 ? (
                            this.state.withdrawList.data.map(function(val, index){                        
                              return (
                                <tr key={`wl${index}`}>
                                  <td> {val.t_hash} </td>
                                  <td className="text-success"> {parseFloat(val.amount_from).toFixed(4)} {t('mainContent.BNB')}</td>
                                  <td className="text-success"> {parseFloat(val.withdrawable_amount).toFixed(4)} {t('mainContent.BNB')}</td>
                                  <td className="text-success"> {parseFloat(val.reinvestment_amount).toFixed(4)} {t('mainContent.BNB')}</td>                                                       
                                  <td> {val.remarks} </td>
                                  <td> {val.status} </td>
                                  <td> {val.created_at} </td>
                                </tr>
                              )
                            })
                          ):(
                            <tr>
                              <td colSpan="7" className="text-center">{t('mainContent.No_Data_Available')}</td>
                            </tr>
                          )
                        ) : (
                        <tr>
                          <td colSpan="7" className="text-center">{t('mainContent.No_Data_Available')}</td>
                        </tr>
                        )}
                        </tbody>
                      </table>
                      {this.state.withdrawList && this.state.withdrawList.data.length>0 ? (
                        <div className="text-center">
                          <ul className="table_pagination mb-0">
                            <li style={{cursor:this.state.withdrawList.prev_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.withdrawList.prev_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)}><i class="fa fa-angle-double-left"></i></a>
                            </li>
                            {this.state.withdrawList.pages.length>0 ? (
                              this.state.withdrawList.pages.map(function(val, index){
                                let className = "";
                                if(val == this2.state.withdrawList.current_page){
                                  className = "active";
                                }
                                return (
                                  <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.withdrawList.current_page?'pointer':'initial'}}>
                                    <a onClick={()=>this2.getPaginationData(null,'withdraw_page',val,null,null)}>{val}</a>
                                  </li>
                                )
                              })
                            ):(null)}
                            <li style={{cursor:this.state.withdrawList.next_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.withdrawList.next_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)}><i class="fa fa-angle-double-right"></i></a>
                            </li>
                          </ul>
                        </div>
                      ):(null)}
                    </div>
                  </div>
                  <div className="box_line"></div>
                </div>
              </div>
            </div>

            <div className="pb_30 pt_30">
              <div className="container">
                <div className="md_container">
                  <h3 className="heading_text">{t('mainContent.Matching_Payout_Log')}</h3>
                  <div className="box_line"></div>
                  
                  <div className="padding_20">
                    <div className="table-responsive">
                      <table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>{t('mainContent.Total_Subscription')}</th>
                            <th>{t('mainContent.Distribution_Amount')}</th>
                            <th>{t('mainContent.Total_Matching_Points')}</th>
                            <th>{t('mainContent.Your_Matching_Points')}</th>
                            <th>{t('mainContent.Unit_Value')}</th>
                            <th>{t('mainContent.Payout')}</th>
                            <th>{t('mainContent.Date')}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.binaryPayoutLog ? (
                          this.state.binaryPayoutLog.data.length>0 ? (
                            this.state.binaryPayoutLog.data.map(function(val, index){                        
                              return (
                                <tr key={`bll${index}`}>
                                  <td className="text-success"> {parseFloat(val.calculate_total_subs_amount).toFixed(4)} {t('mainContent.BNB')}</td>
                                  <td className="text-success"> {parseFloat(val.calculate_total_bonus_amount).toFixed(4)} {t('mainContent.BNB')}</td>
                                  <td className="text-success"> {parseFloat(val.calculate_all_user_payout).toFixed(4)} {t('mainContent.BNB')}</td>
                                  <td className="text-success"> {parseFloat(val.total_payout).toFixed(4)} {t('mainContent.BNB')}</td>                            
                                  <td className="text-success"> {val.per_commission} {t('mainContent.BNB')}</td>
                                  <td className="text-success"> {parseFloat(val.bonus_amount).toFixed(4)} {t('mainContent.BNB')}</td>
                                  <td> {val.created_at} </td>
                                </tr>
                              )
                            })
                          ):(
                            <tr>
                              <td colSpan="7" className="text-center">{t('mainContent.No_Data_Available')}</td>
                            </tr>
                          )
                        ) : (
                        <tr>
                          <td colSpan="7" className="text-center">{t('mainContent.No_Data_Available')}</td>
                        </tr>
                        )}
                        </tbody>
                      </table>
                      {this.state.binaryPayoutLog && this.state.binaryPayoutLog.data.length>0 ? (
                        <div className="text-center">
                          <ul className="table_pagination mb-0">
                            <li style={{cursor:this.state.binaryPayoutLog.prev_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.binaryPayoutLog.prev_page_url,null,null,this.state.binaryPayoutLog.current_page,this.state.binaryPayoutLog.last_page)}><i class="fa fa-angle-double-left"></i></a>
                            </li>
                            {this.state.binaryPayoutLog.pages.length>0 ? (
                              this.state.binaryPayoutLog.pages.map(function(val, index){
                                let className = "";
                                if(val == this2.state.binaryPayoutLog.current_page){
                                  className = "active";
                                }
                                return (
                                  <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.binaryPayoutLog.current_page?'pointer':'initial'}}>
                                    <a onClick={()=>this2.getPaginationData(null,'binary_page',val,null,null)}>{val}</a>
                                  </li>
                                )
                              })
                            ):(null)}
                            <li style={{cursor:this.state.binaryPayoutLog.next_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.binaryPayoutLog.next_page_url,null,null,this.state.binaryPayoutLog.current_page,this.state.binaryPayoutLog.last_page)}><i class="fa fa-angle-double-right"></i></a>
                            </li>
                          </ul>
                        </div>
                      ):(null)}
                    </div>
                  </div>
                  <div className="box_line"></div>
                </div>
              </div>
            </div>

            <div className="pb_20 pt_60">
              <div className="container">
              <div className="dark_box">
                <div className="row cus_row">
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Remaining_Left_Point')}</h4>
                      <h5>{parseFloat(this.state.remaining_left_payout).toFixed(4)}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Total_Left_Point')} </h4>
                      <h5>{this.state.left_subscription_count?this.state.left_subscription_count:0}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Paid_Left_Point')}</h4>
                      <h5>{this.state.left_user_count}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Left_Team')}: {this.state.userDetails.leftTeam}</h4>
                      <h4>{t('mainContent.Left_Referrals')}: {this.state.userDetails.leftDirect}</h4>
                      {/* <h5></h5> */}
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Remaining_Right_Point')} </h4>
                      <h5>{parseFloat(this.state.remaining_right_payout).toFixed(4)}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Total_Right_Point')}</h4>
                      <h5>{this.state.right_subscription_count?this.state.right_subscription_count:0}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Paid_Right_Point')}</h4>
                      <h5>{this.state.right_user_count}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Right_Team')}: {this.state.userDetails.rightTeam}</h4>
                      <h4>{t('mainContent.Right_Referrals')}: {this.state.userDetails.rightDirect}</h4>
                      {/* <h5></h5> */}
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>

            {/* <div className="pb_30 pt_30">
              <div className="container">
                <ul className="pdf_ul">
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_English.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.English_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_French.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.French_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_German.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.German_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Indonesian.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.Indonesian_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Korean.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.Korean_PDF')}</span>                      
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Russian.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.Russian_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Tagalog.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.Tagalog_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Japanese.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.Japanese_PDF')}</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Vietnamese.pdf">
                      <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                      <span className="span_2">{t('mainContent.Vietnamese_PDF')}</span>
                    </a>
                  </li>
                </ul>
            </div>
          </div> */}

          <div className="pb_30 pt_30">
            <div className="container">
              <div className="">
              <div className="box_line mb_40"></div>
                <h4 className="referal_text word-break">
                  <a className="footer_contact text-center" 
                    href="https://bscscan.com/address/0x6e7f15b48cf7418a676169f249a98ffa7b1c26dc" target="_blank">
                    {t('mainContent.Contract')}: 0x6e7f15b48cf7418a676169f249a98ffa7b1c26dc
                    </a>
                </h4>                
              </div>
            </div>
          </div>          

          <div>
            <footer className="pb_60 pt_30">
              <div className="container">
                <div className="box_line mb_40"></div>
                <div className="md_container">
                  <img className="footer_logo " src="img/dark_logo.png" alt="" />
                  <p className="copy_text">&copy; {t('mainContent.All_Rights_Reserved')} </p>
                </div>
                
              </div>
            </footer>
          
            <a href="javascript:" id="return-to-top">{t('mainContent.Go_To_Top')}</a>

            <div className="overlay">
              <div className="overlayDoor"></div>
              <div className="overlayContent">
                  <div className="">
                      <img src="img/light_logo.png?fd" alt="" />
                      <p>{t('mainContent.Loading.....')}</p>
                  </div>
              </div>
            </div>
          </div>
        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }


  export default withRouter(withTranslation()(withStyles(useStyles)(withSnackbar(Details))));