import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <div>
        <footer className="pb_60 pt_30">
          <div className="container">
            <div className="box_line mb_40"></div>
            <div className="md_container">
              <img className="footer_logo " src="img/dark_logo.png" alt="" />
              <p className="copy_text">&copy; 2021 NihaoBNB All Rights Reserved. </p>
            </div>
            
          </div>
        </footer>
      
      <a href="javascript:" id="return-to-top">Go To Top</a>


      <div className="overlay">
        <div className="overlayDoor"></div>
        <div className="overlayContent">
            <div className="">
                <img src="img/dark_logo.png?fd" alt="" />
                <p>Loading.....</p>
            </div>
        </div>
      </div>

      </div>

      );
    }
  }

  export default Footer;